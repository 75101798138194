import request from '../utils/request'

/**
 * 
 * @returns 首页今日数据
 */
export const getData = (data) =>  request.post('/index/index', data)

/**
 * 
 * @returns 首页门店基础信息
 */
export const getInfo = (data) =>  request.post('/index/info',  data)

/**
 * 
 * @returns 核销
 * 
 */
 export const writeOff = (data) =>  request.post('/order/verify',  data)

 /**
 * 
 * @returns 核验准备-拉取信息
 * 
 */
 export const  tiktok_verify_perpar = (data) =>  request.get('tiktok/verify_perpar',  {params:data})

/**
 * 
 * @returns 更新推广码
 */
export const updateImg = (data) =>  request.post('/upload/upyun',  data)

 /**
 * 
 * @returns 核验准备-拉取信息
 * 
 */
 export const  tiktok_verify_back = (data) =>  request.get(`tiktok/verify_back`,  {params:data})