
import request from '../utils/request'

/**
 * 
 * @returns 根据type 1 - 6 获取不同状态订单
 */
export const getOrder = (data) => request.post('/order/list', data)

/**
 * 
 * @returns 获取司机
 */
export const getCar = (data) => request.post('/order/master', data)

/**
 * 
 * @returns 选择司机
 */
export const activeCar = (data) => request.post('/order/assign', data)


/**
 * 
 * @returns 获取订单详情
 */
export const getOrderDetail = (data) => request.post('/order/info', data)


/**
 * 
 * @returns 异常获取商品
 */
export const getExceptionHandling = (data) => request.post('/order/products', data)

/**
 * 
 * @returns 提交异常订单
 */
export const putAbnormal = (data) => request.post('/order/abnormal', data)


/**
 * 
 * @returns 完成订单
 */
export const completeAbnormal = (data) => request.post('/order/finish', data)


/**
 * 
 * @returns 订单核销
 */
export const order_dispel = (data) => request.post('/order/dispel', data)


/**
 * 
 * @returns 获取首字母列表
 */
export const carbase_letter = (data) => request.post('/car/letter', data)


/**
 * 
 * @returns 获取车型菜单
 */
export const car_menu = (data) => request.post('/car/menu', data)


/**
 *
 * @returns car/base_list 获取车型列表
 */
export const car_base_list = (data) => request.post('/car/base_list', data)

/**
*
* @returns car/base_list 获取套餐列表
*/
export const thali_list = (data) => request.post('/thali/list', data)


/**
* /thali/services

* @returns car/base_list 获取服务列表
*/
export const thali_services = (data) => request.post('/thali/services', data)


/**
* /thali/services

* @returns car/base_list 提交工单
*/
export const order_add = (data) => request.post('/order/add', data)


/**
* /thali/services

* @returns car/base_list 车牌列表
*/
export const order_car_list = (data) => request.post('/order/car_list', data)


/**
* /thali/services

* @returns car/base_list 支付订单
*/
export const order_pay = (data) => request.post('/order/pay', data)

/**
* /thali/services

* @returns car/base_list 全部工单
*/
export const order_workorder = (data) => request.post('/order/workorder', data)


/**
* /thali/services

* @returns car/base_list 退款工单信息
*/
export const order_refund_order = (data) => request.post('/order/refund_order', data)



/**
* /thali/services

* @returns car/base_list 退款工单信息
*/
export const order_refund = (data) => request.post('/order/refund', data)

/**
* /order/show

* @returns /order/show 全部工单的详情
*/
export const order_show = (data) => request.post('/order/show', data)




/**
* /order/show

* @returns /order/show 全部工单的详情
*/
export const order_order_count = (data) => request.post('/order/order_count', data)