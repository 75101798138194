<template>
   <div class="my-card">
       <div class="header-title">
           <div class="card-title">
            <el-icon class="el-icon-arrow-left icon" @click.native="$router.go(-1)"></el-icon>
            <span class="btn" @click="$router.go(-1)">返回</span>
           </div>
          <span class="title">{{ title }}</span>
       </div>
       <div class="line"></div>
       <div>
           <slot name="main"></slot>
       </div>
   </div>
</template>
<script>
export default {
    name:'MyCard',
    props: {
        title: {
            required: true,
            type: String
        }
    },
    data() {
     return {
       
     }
   },
   methods: {
     
   }
};
</script>
<style lang='scss' scoped>
.my-card {
     width: 100%;
     min-height: 80vh;
     overflow: hidden;
     padding: 50px 0;
     .header-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 20px;
        .card-title {
            display: flex;
            align-items: center;
            .btn {
                padding: 2px 10px;
                font-size: 16px;
                background-color: #16C2B2;
                cursor: pointer;
                border-radius: 5px;
                color: #e8e8e8;
                box-shadow: 1px 1px 1px 1px #16C2B2;
            }
            .btn:hover {
                transform: scale(1.01);
                transition: all 0.5s;
            }
        }
        .icon {
            cursor: pointer;
            font-weight: 550;
            font-size: 28px;
        }
        .title {
            margin-left: 20px;
            font-size: 23px;
            font-weight: 550;
        }
     }
     .line {
        width: 100%;
        height: 1px;
        margin: 30px 0;
        border-bottom: 1px solid #e8e8e8;
     }
} 
</style>