import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';  //本地持久化插件

import user from './modules/user'
Vue.use(Vuex)

const users = createPersistedState({
  key: 'user',
  paths: ['user']
})

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user
  },
  plugins: [
    users
  ]
})
