<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { order_order_count } from "@/api/order"
export default {
  data() {
    return {
      setTime: null,
      isEvent: false,
    }
  },
  mounted() {
    window.addEventListener('mousedown', this.handleMousedown)//监听鼠标按下
    window.addEventListener('mouseup', this.handleMouseup)//监听鼠标抬起
    window.addEventListener('keydown', this.handlekeydown)//监听键盘按下
    // document.addEventListener('mousemove', this.handleMouseMove);
  },
  methods: {
    handleMousedown() {
      if (!this.isEvent) {
        this.isEvent = true;
        this.getNewInfo()
      }
    },
    handleMouseup() {
      if (!this.isEvent) {
        this.isEvent = true;
        this.getNewInfo()
      }
    },
    handlekeydown() {
      if (!this.isEvent) {
        this.isEvent = true;
        this.getNewInfo()
      }
    },
    // 清除定时器
    clearTimeInter() {
      clearInterval(this.setTime)
    },
    //获取新的订单
    getNewInfo() {
      order_order_count().then(res => {
        if (res.status) {
          if (res.data.new_count > 0) {
            this.clickAudio();
            this.$store.commit("user/setNewOerders",res.data.new_count)
          }
        }
      })
      this.clearTimeInter()
      this.setTime = setInterval(this.getNewInfo, 60000)
    },
    // 音频播放
    clickAudio() {
      const audio = document.createElement('audio');
      const src = require('@/assets/audio/remind.mp3')
      audio.src = src;
      audio.autoplay
      // audio.play()
      try {
        audio.play();
      } catch (error) {
        console.error('播放音频时发生错误：', error);
      }
    },
  }
}
</script>

<style lang="scss">
html {
  font-size: 18px;
}

body {
  box-sizing: border-box;
  // margin: 0 0;
  // padding-right: 0 !important;

}

::v-deep.el-button:hover {
  border-color: #16C2B2;
  color: #16C2B2;
}

::v-deep .el-message .el-message__content {
  font-size: 22px !important;
}
</style>
