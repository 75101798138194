import router from "@/router"

export default {
    namespaced: true,
     state: {
        token: '',
        user: '',
        newOerders:0,
     },
     mutations: {
        // 存放token
        setToken(state, data){
            state.token = data
        },
        // 退出登录
        delToken(state){
            state.token = ''
            state.user = ''
            router.push('/login')
        },
        // 存用户信息
        setUser(state,data) {
            state.user = data
        },
        // 有新的订单
        setNewOerders(state,data){
            state.newOerders = data;
        }
     },
     actives: {

     }
}