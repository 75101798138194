import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/css/theme.scss";
import LicenseKeyboard from 'vue-license-keyboard';
import 'vue-license-keyboard/lib/vue-license-keyboard.css';

// import '@/css/element.scss'

import MyTable from '@/components/MyTable'
import MyCard from '@/components/MyCard'
import MyButton from '@/components/MyButton'
import MyDialog from'@/components/MyDialog'
import MyDetail from'@/components/MyDetail'
import MyPagination from'@/components/MyPagination'
import WriteOff from'@/components/WriteOff'
import MyInfo from'@/components/MyInfo'

Vue.component('MyInfo', MyInfo)
Vue.component('WriteOff', WriteOff)
Vue.component('MyPagination', MyPagination)
Vue.component('MyDetail', MyDetail)
Vue.component('MyDialog', MyDialog)
Vue.component('MyButton', MyButton)
Vue.component('MyTable', MyTable)
Vue.component('MyCard', MyCard )

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(LicenseKeyboard);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
