<template>
  <div>
    <!-- 手动核销 -->
    <MyDialog title="核销" :center="true" :show.sync="showHand" width="60%" :body="body" :befor="true" @handClose="canse">
      <template slot="main">
        <div>
          <MyInfo title="用户信息">
            <template #main>
              <div class="flex">
                <div class="text">姓名:&nbsp; {{ form.name }}</div>
                <div class="text">手机号:&nbsp; {{ form.phone }}</div>
                <div class="text">车牌号:&nbsp; {{ form.car_text }}</div>
                <div class="text">车型:&nbsp; {{ form.car.model }}</div>
              </div>
            </template>
          </MyInfo>
          <MyInfo title="用户套餐信息">
            <template #main>
              <div>
                <el-col :span="2" :offset="21">
                  <MyButton text="添加全部" width="5vw" @click.native="addAllUserd">
                  </MyButton>
                  <div style="height:12px"></div>
                </el-col>
              </div>
              <el-table :data="msgData" style="width: 100%" border row-key="id" :tree-props="{ children: 'children' }">
                <el-table-column align="center" label="序号" width="100px">
                  <template #default="{ $index }">
                    {{ $index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="套餐/商品信息" align="center">
                  <template slot-scope="{ row }">
                    {{ row.name == '' ? row.service_name : row.name }}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="套餐/商品图片" align="center">
                  <template slot-scope="{ row }">
                    <el-image style="width: 60px; height: 60px" :src="row.cover" fit="fill"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="unit" label="单位" align="center">
                </el-table-column>
                <el-table-column prop="num" label="数量" align="center">
                  <template slot-scope="{ row }">
                    {{ row.restrict === 1 ? '无限' : row.num }}
                  </template>
                </el-table-column>
                <el-table-column prop="dispel" label="已用次数" align="center">
                </el-table-column>
                <el-table-column prop="residue" label="剩余次数" align="center">
                  <template slot-scope="{ row }">
                    {{ row.restrict === 1 ? '无限' : row.residue }}
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="操作" align="center">
                  <template slot-scope="{ row }">
                    <div class="handButton">
                      <MyButton text="添加" :disabled="row.num === row.dispel && row.restrict === 0" width="5vw"
                        @click.native="addUserd(row)">
                      </MyButton>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </MyInfo>
          <MyInfo title="用户使用套餐">
            <template #main>
              <el-table :data="userData" style="width: 100%" border row-key="id" :tree-props="{ children: 'children' }">
                <el-table-column align="center" label="序号" width="100px">
                  <template #default="{ $index }">
                    {{ $index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="套餐/商品信息" width="180" align="center">
                  <template slot-scope="{ row }">
                    {{ row.name == '' ? row.service_name : row.name }}
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="address" label="商品信息" align="center">
                </el-table-column> -->
                <el-table-column prop="" label="套餐/商品图片" align="center">
                  <template slot-scope="{ row }">
                    <el-image style="width: 60px; height: 60px" :src="row.cover" fit="fill"></el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="residue" label="剩余次数" align="center">
                  <template slot-scope="{ row }">
                    {{ row.restrict === 1 ? '无限' : row.residue }}
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="数量" align="center">
                  <template slot-scope="{ row }">
                    <el-input @input="usedChange(row)" v-model.number="row.use_num"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="操作" align="center">
                  <template slot-scope="{ row , $index }">
                    <MyButton text="删除" width="5vw" @click.native="delData(row, $index)"></MyButton>

                  </template>
                </el-table-column>
              </el-table>
            </template>
          </MyInfo>
        </div>
      </template>
      <template #footer>
        <div class="hand_btn">
          <el-button style="margin-right: 8vw; width: 5vw;" @click="canse">取消</el-button>
          <MyButton text="确定" width="5vw" @click.native="sureHand"></MyButton>
        </div>
      </template>
    </MyDialog>
  </div>
</template>

<script>
import { order_dispel } from '@/api/order'
export default {
  name: 'HandOff',
  props: {
    form: {
      default: {},
      require: true
    },
    body: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      info: '',
      showHand: false,
      msgData: [],
      userData: [],
      isSubmit: false
    }
  },
  watch: {
    form() {
      this.getMsgArr()
    }
  },
  methods: {
    //获取用户套餐产品数组,
    getMsgArr() {
      // 核销码进入
      if (Array.isArray(this.form.products)) {
        let data = JSON.parse(JSON.stringify(this.form))
        let proArr = this.addTypeArr(data.products, 1)
        let serArr = this.addTypeArr(data.services, 2)
        this.msgData = [...proArr, ...serArr]
      }
    },
    //给用户套餐产品数组添加type
    addTypeArr(arr, type) {
      let typeArr = []
      arr.map(item => {
        typeArr.push({ ...item, type })
      })
      return typeArr
    },
    sureHand() {
      if (!this.isSubmit) {
        this.isSubmit = true;
        let result = this.getFinishArr()
        if (result.length == 0) {
          this.$message.warning('核销套餐不能为空');
          this.isSubmit = false;
          return
        }
        order_dispel({ order_id: this.form.id , products: result }).then(res => {
          if (res.status === 1) {
            this.$emit('success');
            this.$message.success(res.msg);
            this.userData = [];
            this.isSubmit = false;
            this.showHand = false;
          } else {
            this.$message.error(res.msg);
            this.isSubmit = false;
          }
        }).catch(err=>{
          this.$message.error(err.message);
          this.isSubmit = false;
        })
      }

    },
    //全部添加
    addAllUserd() {
      this.userData = []
      this.msgData.map(item => {
        if (item.restrict === 1) {
          this.userData.push({ ...item, use_num: 1 })
        } else {
          if (item.residue === 0) {
            return
          }
          this.userData.push({ ...item, use_num: item.residue })
        }
      })
    },
    //添加到使用列表
    addUserd(form) {
      let item = JSON.parse(JSON.stringify(form))
      //使用列表为空时直接push,并结束函数
      if (this.userData.length === 0) {
        this.userData.push({ ...item, use_num: 1 })
        return
      }

      //判断数据是否存在，存在对要使用数+1，否则添加
      if (this.userData.some(items => items.id === form.id)) {
        console.log("this.userData true", this.userData, form);
        this.userData.forEach(el => {
          if (el.id === form.id) {
            // 判断是否无限
            if (item.restrict === 1) {
              el.use_num++;
              return
            }
            // 有限次数添加
            // 判断用户使用数和剩余次数的大小
            let temp = el.use_num + 1
            if (temp > el.residue) {
              this.usedChange(el)
            } else if (el.residue === el.use_num) {
              this.$message.warning('用户使用数已等于剩余次数')
            } else {
              el.use_num++
            }
          }
        })
      } else {
        this.userData.push({ ...item, use_num: 1 })
      }
    },
    //delData删除

    delData(row, index) {
      this.userData.splice(index, 1);
      this.msgData.forEach((item, index) => {
        if (item.id == row.id) {
          this.msgData.splice(index, 1, { ...row })
          console.log(row);

        }
      })
    },
    usedChange(row) {
      if (row.use_num < 1) {
        this.$confirm('使用次数不能小于等于0,最小次数为1', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: true,
          type: 'warning'
        }).then(() => {
          row.use_num = 1
        }).catch(() => {
          row.use_num = 1
        });
      }
      // 如果无限次，就不要判断剩余次数和使用次数
      if (row.restrict === 1) {
        return
      }
      if (row.use_num > row.residue) {
        this.$confirm('使用次数不能大于剩余次数，关闭默认为剩余次数', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: true,
          type: 'warning'
        }).then(() => {
          row.use_num = row.residue
        }).catch(() => {
          row.use_num = row.residue
        });
      }
    },
    canse() {
      this.userData = [];
      this.showHand = false;
    },
    // 获取订单核销需要的数组
    getFinishArr() {
      let arr = []
      this.userData.map(item => {
        arr.push({ id: item.id, type: item.type, num: item.use_num })
      })
      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
.handButton {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 40px;
    text-align: center;

  }
}

::v-deep .el-input__inner {
  text-align: center;
}

.hand_btn {
  display: flex;
  justify-content: space-evenly;
}

.flex {
  display: flex;
  width: 100%;

  .text {
    min-width: 120px;
    padding: 10px 20px;

    // img {
    //   width: 80px;
    //   height: 50px;
    //   border-radius: 8px;
    //   transform: translateY(20px);
    // }
  }

}
</style>