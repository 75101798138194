<template>
  <div>
    <!-- 核销 -->
    <MyDialog :show.sync="show" title="核销" width="25%">
      <template #main>
        <div class="iconImage" v-if="false">
          <div :class="{ 'active': active == item.value }" @click="active = item.value" v-for="item in list"
            :key="item.value">
            <el-image :src="item.img"></el-image>
            <div class="sanjiao" v-if="active == item.value"><i class="el-icon-check"></i></div>
          </div>
        </div>
        <div class="writeOff">
          <el-input ref="inputs" :readonly="readonly" @keyup.enter="writeOffInput" style="width: 18vw;" v-model="code"
            placeholder="请用扫码输入核销码" @input="writeOffInput" autofocus>
            <template #prefix>
              <img src="../assets/layout/he.png" alt="" class="img">
            </template>
          </el-input>
        </div>
        <!-- <div class="btn">
          <el-button style="margin-right: 8vw; width: 5vw;" @click="show = false">取消</el-button>
          <MyButton text="核销" width="5vw" @click.native="writeOff"></MyButton>
        </div> -->
      </template>
    </MyDialog>
    <!-- 核销核实弹窗 -->
    <HandOff ref="handOff" @success="success" :form="form"></HandOff>
    <!-- 抖音核销弹窗 -->
    <MyDialog :show.sync="showDouyin" title="核销" width="25%">
      <template #main>

      </template>
    </MyDialog>
  </div>

</template>
<script>
import HandOff from './HandOff.vue';
import { writeOff, tiktok_verify_perpar } from '@/api/index';
import { message } from '@/utils/message';

export default {
  components: {
    HandOff
  },
  data() {
    return {
      form: {},
      code: '',
      show: false,
      focus: true,
      id: '',
      isIn: false,
      active: 'normal',
      list: [
        {
          img: require('@/assets/allorder/logo_che.png'),
          value: 'normal'
        },
        {
          img: require('@/assets/allorder/douyin.png'),
          value: 'tiktok'
        },
      ],
      readonly: false,
      showDouyin: false,
      dList: {
        "0": {
          "sku": {
            "third_sku_id": "",
            "title": "【轿车专享】水釉洗车",
            "account_id": "7324590814662543400",
            "groupon_type": 1,
            "market_price": 4000,
            "sku_id": "1799186265034794",
            "sold_start_time": 1715837553
          },
          "amount": {
            "coupon_pay_amount": 3200,
            "list_market_amount": 4000,
            "original_amount": 3200,
            "pay_amount": 3200,
            "brand_ticket_amount": 0
          },
          "start_time": 1720425424,
          "expire_time": 1751558399,
          "encrypted_code": "CgYIASAHKAESLgos+l2P25sRdJQTRim63PpH4KIvCJglQ8D6xuDjo90LpIdzeeaVIbeHhT3iT5kaAA=="
        },
        "verify_token": "3792934e-9db7-4240-a34e-1ba05595c8e4"
      }
    }
  },
  watch: {
    show(newval) {
      this.$nextTick(() => {
        if (newval) {
          this.$refs.inputs.focus()
        }
      })
    }
  },
  methods: {
    async writeOff() {

      const res = await writeOff({
        code: this.code,
        order_id: this.id || ''
      })
      //  console.log(res);
      if (res.status == 1) {
        this.code = ''
        this.id = ''
        this.show = false
        this.$emit('success', true)
        if ([8, 10].includes(res.data.type)) {
          this.form = res.data;
          this.$refs.handOff.showHand = true;
          return
        }
        message(res.msg, 'success')
      } else {
        this.code = ''
        this.id = ''
        this.show = false
        message(res.msg, 'error')
        this.$emit('success', false)
      }
    },
    writeOffInput() {
      if (this.active != 'normal') {
        this.heXiaoDouyin();
        return
      }
      if (this.code.length < 12) {
        return
      }
      if (!this.isIn) {
        this.isIn = true
        setTimeout(() => {
          this.writeOff();
          this.isIn = false;
        }, 500);
      }
    },
    success() {
      this.$refs.handOff.showHand = false;
    },
    // 抖音核销
    heXiaoDouyin() {
      if (this.active === 'tiktok' && this.code.length == 15 && !/^(http:|https:)?\/\//.test(this.code)) {
        this.readonly = true
        this.getHexiao({ code: this.code });
        return
      }
      if (this.active === 'tiktok' && /^(http:|https:)?\/\//.test(this.code)) {
        this.readonly = true
        this.getHexiao({ encrypted_data: this.code });
        return
      }
    },
    // 抖音等。。。第三方核销请求
    getHexiao(key) {
      tiktok_verify_perpar({
        type: this.active,
        ...key
      }).then(res => {
        if (res.status) {
          this.$message.success('核销成功');
          this.readonly = false
          this.code = ''
          this.show = false
        } else {
          this.readonly = false
          this.$message.error(res.msg);
          this.$emit('success', true)
        }
      })
    }
  },
}
</script>
<style lang='scss' scoped>
::v-deep.el-button:hover {
  border-color: #16C2B2;
  color: #16C2B2;
}

::v-deep .el-input__inner {
  height: 50px;
  padding-left: 70px;
}

::v-deep .el-button:active {
  border-color: #16C2B2;
  color: #16C2B2;
}

.writeOff {
  display: flex;
  justify-content: center;
  min-height: 60px;

  .img {
    width: 100%;
    height: 50px;
  }
}

.btn {
  margin-top: 40px;
  display: flex;
  justify-content: space-evenly;
}

.iconImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 52px;

  >div {
    margin: 0 26px;
    width: 97px;
    position: relative;
    height: 93px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(160, 160, 160, 0.25);

    .el-image {
      width: 71px;
      height: 71px;
    }

    .sanjiao {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 24px;
      height: 24px;
      text-align: end;
      background-image: linear-gradient(135deg, transparent 50%, rgba(238, 47, 5, 1) 50%);

      // background-color: rgba(238, 47, 5, 1);;
      .el-icon-check {
        color: white;
        position: relative;
        bottom: -4px;
      }
    }
  }

  .active {
    border: 1px solid rgba(238, 47, 5, 1);
  }
}
</style>