<template>
    <div class="max">
        <div class="title">
            {{ title }}
        </div>
        <div class="font">
            <slot name="main"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            // required: true
        }
    },
    data() {
        return {

        }
    },

    methods: {

    }
};
</script>
<style lang='scss' scoped>
.max {
    padding: 20px 40px;
    margin-right: 50px;

    .title {
        font-weight: 550;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
    }

    .font {
        font-size: 16px;
        color: #999999;
        line-height: 20px;
    }
}
</style>