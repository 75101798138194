<template>
   <div>
       <div class="header">
           {{ title }}
       </div>
       <div class="detail-box">
            <div class="item" v-for="(item,index) in arr" :key="index" :style="{'width': width}">
                <div v-if="item.name">
                    {{ item.name }}:
                </div>
                <div>
                     <div v-if="!item.slot"> {{ item.prop}}</div>
                     <slot v-else :name="item.slot" :scoped="item"></slot>
                </div>
            </div>
       </div>
   </div>
</template>
<script>
export default {
   name: 'MyDetail',
   props: {
     title: {
        required: true,
        type: String
     },
     arr: {
        required: true,
        type: Array,
     },
     width:{
        default: '270px',
        type: String
     }
   },
   data() {
     return {
       
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
     
   }
};
</script>
<style lang='scss' scoped>
   .header {
      font-weight: 550;
      margin: 25px 0;
   }
   .detail-box {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      .item {
        box-sizing: border-box;
        padding: 10px 5px;
        border: 1px solid #e9e9e9;
        //   box-shadow: 0 0 0 1px #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
   }
   
</style>