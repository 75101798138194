<template>
       <el-button class="color" :disabled="disabled" :class="{'disabled': disabled}" :style="{'width': width}" :loading="loading">{{ text }}</el-button>
</template>
<script>
export default {
   name: 'MyButton',
   props: {
     text: {
        required: true,
        type: String
     },
     disabled: {
        default: false,
        type: Boolean
     },
     width: {
        type: String
     },
     loading:{
       default: false,
       type: Boolean
     }
   },
   data() {
     return {
       
     }
   },
   methods: {
     
   }
};
</script>
<style lang='scss' scoped>

.disabled {
  opacity: 0.5;
}

::v-deep .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover {
    color: #fff ;
    background-color: #16C2B2;
}
.color {
    color: #fff !important;
    background-color: #16C2B2;
}
</style>